<template>
  <v-container>
    <div v-if="checkIfPermission('view post', admin.permissions)">
      <v-dialog v-model="createDialog" max-width="650">
        <v-card>
          <v-card-title>
            <span class="headline"
              >{{ isEditing ? 'Update' : 'Create' }} Post</span
            >
          </v-card-title>
          <v-card-title>
            <v-form ref="form">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="newPost.title"
                    label="Title"
                    filled
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="newPost.image"
                    filled
                    label="Image"
                    required
                    :rules="[rules.required]"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <VueEditor v-model="newPost.content" />
                </v-col>
              </v-row>
            </v-form>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="createPost" :loading="isCreating">{{
              isEditing ? 'Update' : 'Create'
            }}</v-btn>
            <v-btn color="error" @click="createDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <h1 class="mb-5">Trader's Post</h1>
      <v-card flat>
        <v-container>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              v-if="checkIfPermission('create post', admin.permissions)"
              depressed
              @click="createDialog = true"
            >
              <v-icon left>mdi-plus</v-icon>
              Create New Post</v-btn
            >
          </v-toolbar>
        </v-container>
        <v-card-text>
          <v-data-table
            :headers="tableheader"
            :items="posts"
            :loading="loadingPost"
            :single-expand="true"
            show-expand
            item-key="_id"
          >
            <template v-slot:[`item.createdAt`]="{ item }">
              <span>
                {{ item.createdAt | formatDate }}
              </span>
            </template>
            <template v-slot:[`item.updatedAt`]="{ item }">
              <span>
                {{ item.updatedAt | formatDate }}
              </span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <span
                :class="`d-block text-center text-capitalize rounded py-2 px-3 white--text ${
                  item.current ? 'success' : 'error'
                }`"
                >{{ item.current ? 'Current' : 'Not Current' }}</span
              >
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-menu
                v-if="checkIfPermission('manage post', admin.permissions)"
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item ripple>
                    <v-list-item-title @click="selectedPost(item)"
                      >View</v-list-item-title
                    >
                  </v-list-item>

                  <v-list-item
                    v-if="!item.current"
                    @click="makeCurrent(item._id)"
                    ripple
                  >
                    <v-list-item-title>Make Current</v-list-item-title>
                  </v-list-item>
                  <v-list-item ripple @click="deletePost(item._id)">
                    <v-list-item-title class="text--error"
                      >Delete</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <div style="padding: 5px" v-html="item.content"></div>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>

    <template v-else>
      <h1 class="error--text">You don't have access to this page</h1>
    </template>
  </v-container>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { mapState } from 'vuex'
import traderPost from '../../api/trader-post'

const tableheader = [
  {
    value: 'title',
    text: 'Title'
  },
  {
    value: 'createdAt',
    text: 'Posted Date'
  },
  {
    value: 'updatedAt',
    text: 'Updated Date'
  },
  {
    value: 'status',
    text: 'Status'
  },
  {
    value: 'action',
    text: '',
    sortable: false
  }
]

export default {
  name: 'TraderPost',
  components: {
    VueEditor
  },
  data: () => ({
    tableheader,
    createDialog: false,
    isCreating: false,
    posts: [],
    loadingPost: false,
    isEditing: false,
    newPost: {
      title: '',
      image: null,
      content: ''
    },
    rules: {
      required: (value) => !!value || 'Required',
      number: (value) =>
        (!!value && /^[0-9]+$/.test(value)) || 'Must be a number'
    }
  }),
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  },
  created() {
    this.getPosts()
  },
  filters: {
    formatDate(value) {
      return new Date(value).toLocaleString()
    }
  },
  methods: {
    async selectedPost(post) {
      this.isEditing = true
      this.newPost = post
      this.createDialog = true
    },
    async getPosts() {
      this.loadingPost = true
      const res = await traderPost.traderPost().getPosts({
        orderBy: 'current',
        order: 'desc'
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loadingPost = false
        return
      }

      this.loadingPost = false
      this.posts = res.data.data.data
    },
    async createPost() {
      if (this.isEditing) {
        this.editPost()
        return
      }

      if (!this.$refs.form.validate()) return
      this.isCreating = true

      const formData = new FormData()
      formData.append('title', this.newPost.title)
      formData.append('image', this.newPost.image)
      formData.append('content', this.newPost.content)

      const res = await traderPost.traderPost().createPost(formData)

      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.isCreating = false
        return
      }

      this.getPosts()
      this.createDialog = false
      this.isCreating = false
    },
    async makeCurrent(id) {
      const res = await traderPost.traderPost().makePostCurrent({ id })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.getPosts()
    },
    async deletePost(id) {
      const res = await traderPost.traderPost().deletePost({ id })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.getPosts()
    },
    async editPost() {
      if (!this.$refs.form.validate()) return
      this.isCreating = true

      const formData = new FormData()
      formData.append('title', this.newPost.title)
      if (this.newPost.image && typeof this.newPost.image === 'object') {
        formData.append('image', this.newPost.image)
      }
      formData.append('content', this.newPost.content)

      const res = await traderPost
        .traderPost()
        .updatePost({ id: this.newPost._id, data: this.newPost })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.getPosts()

      this.newPost = {
        title: '',
        image: null,
        content: ''
      }
      this.isCreating = false
      this.isEditing = false
      this.createDialog = false
    }
  }
}
</script>

<style></style>
